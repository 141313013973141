<template>
  <router-view />
</template>

<script>
import firebase from '@/utils/firebase';
import { organizationsCollection } from '@/utils/firebase';
import moment from 'moment';

export default {
  name: 'App',
  mounted() {
    this.initApp();
  },
  methods: {
    initApp() {
      firebase.auth().onAuthStateChanged(async (user) => {
        let dt = new Date();
        if (user) {
          let refresh = false;
          let elapsed = 0;
          let cookie = '; ' + document.cookie;
          let parts = cookie.split('; firebaseAccessTimer=');
          if (parts.length == 2) {
            elapsed = dt.getTime() - parts.pop().split(';').shift();
          }
          if (55 * 60 * 1000 < elapsed) {
            document.cookie =
              'firebaseAccessTimer=' + dt.getTime() + '; path=/';
            refresh = true;
          }
          const jsonUser = await user.toJSON();
          const idTokenResult = await user.getIdTokenResult(refresh);
          document.cookie =
            'firebaseAccessToken=' + idTokenResult.token + '; path=/';
          if (idTokenResult.claims.sub_id) {
            try {
              let customerEndDate = null;
              let credit = null;

              let ref = await organizationsCollection
                .doc(idTokenResult.claims.org_id)
                .collection('sub')
                .doc(idTokenResult.claims.sub_id)
                .get();
              if (ref.exists) {
                if (ref.data().endDate && ref.data().endDate.seconds) {
                  const nowDate = new Date();
                  const endDate = new Date(
                    ref.data().endDate.seconds * 1000 +
                      nowDate.getTimezoneOffset() * 60000,
                  );
                  customerEndDate = moment(endDate).format('YYYY-MM-DD');
                }
                if (ref.data().credit) {
                  credit = +ref.data().credit;
                }
              }
              this.$store.dispatch('user/signInWithUserAndToken', {
                user: jsonUser,
                token: idTokenResult.token,
                claims: idTokenResult.claims,
                customerEndDate: customerEndDate,
                tokenCredits: credit,
              });
            } catch (err) {
              this.$store.dispatch('notification/add', {
                type: 'error',
                message: `An error occurred : ${err.message}`,
              });
            }
          } else {
            this.$store.dispatch('user/signInWithUserAndToken', {
              user: jsonUser,
              token: idTokenResult.token,
              claims: idTokenResult.claims,
              customerEndDate: '',
              tokenCredits: '',
            });
          }

          if (idTokenResult.claims.role_code !== 'AAD') {
            let refOrg = await organizationsCollection
              .doc(idTokenResult.claims.org_id)
              .get();

            if (refOrg.exists) {
              this.$store.dispatch('user/changeOrg', {
                orgId: refOrg.data().id,
                orgName: refOrg.data().name,
                projectId: refOrg.data().project_id,
                isTest: refOrg.data().is_test || false,
              });
            }
          }
        }
      });
    },
  },
};
</script>
