<template>
  <div>
    <v-row class="mt-3">
      <v-col v-for="filter in filters" :key="filter.id" cols="12" sm="6" md="4">
        <div :class="{ 'group-select': isAdmin }">
          <v-select
            v-model="values[filter.name]"
            :items="filter.values"
            :menu-props="{ offsetY: true, nudgeTop: -5 }"
            :label="filter.name"
            :required="filter.required"
            multiple
            item-color=""
            placeholder="Select an item..."
            outlined
            dense
            @change="
              updateValue(filter);
              moveToTop(values[filter.name], filter);
            "
          >
            <template #selection="{ item, index }">
              <span v-if="index === 0">{{ item }}</span>
              <span v-if="index === 1" class="grey--text text-caption ml-1">
                (+{{ values[filter.name].length - 1 }} others)
              </span>
            </template>
            <template #item="{ item, attrs }">
              <v-list-item-content>
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon v-if="attrs.inputValue">mdi-check</v-icon>
              </v-list-item-icon>
            </template>
          </v-select>
          <div v-if="isAdmin" class="group-btn">
            <v-menu bottom left offset-y>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list dense>
                <v-list-item link @click="editFilter(filter)">
                  <v-list-item-icon class="mr-2">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Edit filter details</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="openConfigure(filter)">
                  <v-list-item-icon class="mr-2">
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Configure options</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="confirmDelete(filter)">
                  <v-list-item-icon class="mr-2">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Delete filter</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>
      <v-col v-if="isAdmin" cols="12" sm="6" md="4">
        <v-btn
          color="primary"
          text
          rounded
          class="text-normal"
          @click="openFilter"
        >
          <v-icon class="mr-1">mdi-plus-circle-outline</v-icon>
          Add filter
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogFilter" width="450" content-class="rounded-xl">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="px-2">
          <v-card-text class="text-h3 font-weight-medium">
            {{ formLabel }}
          </v-card-text>
          <v-card-text>
            <v-text-field
              ref="name"
              v-model="form.name"
              label="Name"
              placeholder="Name this filter..."
              autofocus
              outlined
              dense
              required
            />
            <v-select
              v-model="form.multiSelect"
              label="List type"
              placeholder="Choose a list type..."
              outlined
              dense
              required
              :menu-props="{ auto: true, offsetY: true, nudgeTop: -5 }"
              :items="types"
              item-text="label"
              item-value="value"
            >
              <template #selection="{ item, index }">
                <span v-if="index === 0">{{ item.label }}</span>
              </template>
              <template #item="{ item, attrs }">
                <v-list-item-icon class="mr-3">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.label"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="ml-3">
                  <v-icon v-if="attrs.inputValue">mdi-check</v-icon>
                </v-list-item-icon>
              </template>
            </v-select>
            <v-select
              v-model="form.allowedCollectionTypes"
              label="Collections"
              placeholder="Select all that apply..."
              outlined
              dense
              multiple
              required
              :menu-props="{ auto: true, offsetY: true, nudgeTop: -5 }"
              :items="collections"
              item-text="label"
              item-value="value"
            >
              <template #selection="{ item, index }">
                <span v-if="index === 0">{{ item.label }}</span>
                <span v-if="index === 1" class="grey--text text-caption ml-1">
                  (+{{ form.allowedCollectionTypes.length - 1 }} others)
                </span>
              </template>
              <template #item="{ item, attrs }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.label"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="ml-3">
                  <v-icon v-if="attrs.inputValue">mdi-check</v-icon>
                </v-list-item-icon>
              </template>
            </v-select>
          </v-card-text>
          <v-card-actions class="pb-5 pr-3">
            <v-spacer />
            <v-btn
              color="primary"
              outlined
              rounded
              class="text-normal"
              @click="dialogFilter = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled="isDisabled"
              color="primary"
              dark
              rounded
              depressed
              class="text-normal"
              @click="saveFilter"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="400" content-class="rounded-xl">
      <v-card>
        <v-card-title class="text-left mb-0 font-weight-medium">
          Delete {{ form.name }} filter
        </v-card-title>
        <v-card-text class="pt-1 grey--text text--darken-1">
          This filter and its list of options will be removed from all
          {{ form.allowedCollectionTypes.join(', ') }}. Still want to delete it?
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            rounded
            class="text-normal"
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            dark
            rounded
            depressed
            class="text-normal"
            @click="deleteItem"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedItem.id"
      v-model="dialogConfigure"
      width="450"
      content-class="rounded-xl"
    >
      <content-filter-configure
        :orgId="orgId"
        :filter="selectedItem"
        @closeConfigure="closeConfigure"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { db } from '@/utils/firebase';
import ContentFilterConfigure from './ContentFilterConfigure';
import firebase from 'firebase/compat/app';

export default {
  components: {
    ContentFilterConfigure,
  },
  props: {
    value: {
      type: Object,
      default() {
        return new Object();
      },
    },
    orgId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    collectionType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      values: new Object(),
      dialogFilter: false,
      dialogDelete: false,
      dialogConfigure: false,
      valid: true,
      types: [
        {
          label: 'Single selection list',
          icon: 'mdi-list-status',
          value: false,
        },
        {
          label: 'Multi selection list',
          icon: 'mdi-format-list-checks',
          value: true,
        },
      ],
      collections: [
        { label: 'Drills', value: 'drills' },
        { label: 'Practice Plans', value: 'plans' },
        { label: 'Skills', value: 'skill' },
        { label: 'Tactics', value: 'tactic' },
      ],
      form: {
        name: null,
        multiSelect: null,
        required: false,
        allowedCollectionTypes: new Array(),
        values: new Array(),
      },
      filters: [],
      selectedItem: {
        id: null,
        name: null,
        multiSelect: null,
        required: false,
        allowedCollectionTypes: new Array(),
        values: new Array(),
      },
      oldName: null,
    };
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
    }),
    isDisabled() {
      return this.form.name &&
        this.form.multiSelect !== null &&
        this.form.allowedCollectionTypes.length > 0
        ? false
        : true;
    },
    formLabel() {
      return this.form.id ? 'Edit filter details' : 'Create new filter';
    },
  },
  watch: {
    value: function (val) {
      this.values = val;
      this.filters.forEach((filter) => {
        let values = new Array();
        if (!this.isEmpty(this.values) && this.values[filter.name]) {
          values = this.values[filter.name];
        }
        this.moveToTop(values, filter);
      }, this);
    },
  },
  beforeMount() {
    this.values = this.value;
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    updateValue: function (filter) {
      if (
        !filter.multiSelect &&
        this.values[filter.name] &&
        this.values[filter.name].length > 1
      ) {
        this.values[filter.name] = this.values[filter.name].splice(-1);
        this.$emit('input', this.values);
        return;
      }
      this.$emit('input', this.values);
    },
    fetchData() {
      let fbCollection = db
        .collection('organizations')
        .doc(this.orgId)
        .collection('filters')
        .where('allowedCollectionTypes', 'array-contains', this.collectionType);

      fbCollection.onSnapshot({ includeMetadataChanges: true }, (doc) => {
        let items = [];
        if (doc.docs.length > 0) {
          doc.forEach((item) => {
            let post = item.data();
            post.id = item.id;
            items.push(post);
          });
        }
        this.filters = items;
      });
    },
    openFilter() {
      this.dialogFilter = true;
      this.form = {
        name: null,
        multiSelect: null,
        required: false,
        allowedCollectionTypes: new Array(),
        values: new Array(),
      };
    },
    editFilter(item) {
      this.form = item;
      this.dialogFilter = true;
      this.oldName = item.name;
    },
    async saveFilter() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch('notification/add', {
          message: `This filter is missing one or more required fields.`,
          btnLabel: 'Okay',
          btnColor: 'primary',
        });
        return;
      }
      try {
        let fbCollection = db
          .collection('organizations')
          .doc(this.orgId)
          .collection('filters');

        if (!this.form.id) {
          await fbCollection.doc().set(this.form);
          this.dialogFilter = false;
          await this.fetchData();
          return;
        }

        let fbDrill = db.collection('drills').where('org_id', '==', this.orgId);

        let fbSkill = db.collection('skills').where('org_id', '==', this.orgId);

        let fbPlans = db
          .collection('practice_plans')
          .where('org_id', '==', this.orgId);

        let refDrills = await fbDrill.get();

        let refSkills = await fbSkill.get();

        let refPlans = await fbPlans.get();

        const batch = db.batch();

        refDrills.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.oldName)) {
            return;
          }
          newFilters[this.form.name] = newFilters[this.oldName];
          delete newFilters[this.oldName];
          const newRef = db.collection('drills').doc(post.id);
          batch.update(newRef, { filters: newFilters });
        }, this);

        refSkills.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.oldName)) {
            return;
          }
          newFilters[this.form.name] = newFilters[this.oldName];
          delete newFilters[this.oldName];
          const newRef = db.collection('skills').doc(post.id);
          batch.update(newRef, { filters: newFilters });
        }, this);

        refPlans.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.oldName)) {
            return;
          }
          newFilters[this.form.name] = newFilters[this.oldName];
          delete newFilters[this.oldName];
          const newRef = db.collection('practice_plans').doc(post.id);
          batch.update(newRef, { filters: newFilters });
        }, this);

        const orgRef = fbCollection.doc(this.form.id);
        batch.update(orgRef, {
          allowedCollectionTypes: this.form.allowedCollectionTypes,
          multiSelect: this.form.multiSelect,
          name: this.form.name,
          required: this.form.required,
        });
        await batch.commit();
        this.oldName = null;
        this.dialogFilter = false;
        await this.fetchData();
      } catch (err) {
        this.dialogFilter = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `Failed to store filter`,
        });
      }
    },
    confirmDelete(item) {
      this.form = item;
      this.dialogDelete = true;
    },
    async deleteItem() {
      try {
        let fbCollection = db
          .collection('organizations')
          .doc(this.orgId)
          .collection('filters');

        let fbDrill = db.collection('drills').where('org_id', '==', this.orgId);

        let fbSkill = db.collection('skills').where('org_id', '==', this.orgId);

        let fbPlans = db
          .collection('practice_plans')
          .where('org_id', '==', this.orgId);

        let refDrills = await fbDrill.get();

        let refSkills = await fbSkill.get();

        let refPlans = await fbPlans.get();

        const batch = db.batch();

        refDrills.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.form.name)) {
            return;
          }
          delete newFilters[this.form.name];
          let updateFilters = newFilters;
          if (this.isEmpty(newFilters)) {
            updateFilters = firebase.firestore.FieldValue.delete();
          }
          const newRef = db.collection('drills').doc(post.id);
          batch.update(newRef, { filters: updateFilters });
        }, this);

        refSkills.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.form.name)) {
            return;
          }
          delete newFilters[this.form.name];
          let updateFilters = newFilters;
          if (this.isEmpty(newFilters)) {
            updateFilters = firebase.firestore.FieldValue.delete();
          }
          const newRef = db.collection('skills').doc(post.id);
          batch.update(newRef, { filters: updateFilters });
        }, this);

        refPlans.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.form.name)) {
            return;
          }
          delete newFilters[this.form.name];
          let updateFilters = newFilters;
          if (this.isEmpty(newFilters)) {
            updateFilters = firebase.firestore.FieldValue.delete();
          }
          const newRef = db.collection('practice_plans').doc(post.id);
          batch.update(newRef, { filters: updateFilters });
        }, this);

        const orgRef = fbCollection.doc(this.form.id);
        batch.delete(orgRef);

        await batch.commit();
        this.closeDialog();
      } catch (e) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `Failed to delete filter`,
        });
      }
    },
    closeDialog() {
      this.dialogDelete = false;
      this.form = {
        name: null,
        multiSelect: null,
        required: false,
        allowedCollectionTypes: new Array(),
        values: new Array(),
      };
      this.selectedItem = {
        id: null,
        name: null,
        multiSelect: null,
        required: false,
        allowedCollectionTypes: new Array(),
        values: new Array(),
      };
    },
    openConfigure(item) {
      this.selectedItem = item;
      this.dialogConfigure = true;
    },
    closeConfigure() {
      this.selectedItem = {
        id: null,
        name: null,
        multiSelect: null,
        required: false,
        allowedCollectionTypes: new Array(),
        values: new Array(),
      };
      this.dialogConfigure = false;
    },
    isEmpty(obj) {
      return (
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      );
    },
    moveToTop(values, filter) {
      const idx = this.filters.indexOf(filter);
      if (idx >= 0 && filter.values.length > 0) {
        const newValues = filter.values.sort();
        this.filters[idx].values = this.orderBy(values, newValues);
      }
    },
    orderBy(values, items) {
      const newList = [
        ...items.filter((item) => values && values.indexOf(item) !== -1),
        ...items.filter((item) => values && values.indexOf(item) === -1),
      ];
      return newList;
    },
  },
};
</script>
