import Vue from 'vue';
import Vuex from 'vuex';

// importing modules
import * as notification from '@/store/modules/notification.js';
import * as user from '@/store/modules/user.js';
import * as filter from '@/store/modules/filter.js';

Vue.use(Vuex);

const publishNotification = (type, message, dispatch) => {
  const notification = { type, message };
  dispatch('notification/add', notification, { root: true });
};

export default new Vuex.Store({
  modules: {
    user,
    notification,
    filter,
  },
  state: {
    barColor: 'rgba(5, 152, 117, .8), rgba(33, 223, 133, .8)',
    barImage:
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload;
    },
  },
  actions: {},
});
