import Vue from 'vue';
import Router from 'vue-router';
import AuthService from '@/services/AuthService';
import store from '@/store/store';

Vue.use(Router);

function load(component) {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/${component}.vue`);
}

const routes = [
  {
    path: '/user',
    component: load('layout/Auth'),
    children: [
      { path: 'login', name: 'Login', component: load('pages/Login') },
      {
        path: 'forgot_password',
        name: 'ForgotPassword',
        component: load('pages/ForgotPassword'),
      },
      {
        path: 'reset_password',
        name: 'ResetPassword',
        component: load('pages/ResetPassword'),
      },
      {
        path: 'invitation/:docId',
        name: 'Invitation',
        component: load('pages/Invitation'),
      },
    ],
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/organizations',
    component: load('layout/Root'),
    children: [
      {
        path: 'list',
        name: 'Organizations List',
        component: load('organization/Table'),
      },
      {
        path: 'grid',
        name: 'Organizations Grid',
        component: load('organization/Grid'),
      },
      {
        path: ':id/customers',
        name: 'Customers',
        component: load('organization/Customers'),
      },
      {
        path: ':orgId/customers/:customerId/users',
        name: 'Customer Users',
        component: load('user/CustomerUsers'),
      },
      {
        path: ':id/configuration',
        name: 'OrganizationConfiguration',
        component: load('organization/Configuration'),
      },
      {
        path: ':id/sub/import',
        name: 'Import Organization',
        component: load('organization/Import'),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    component: load('layout/Default'),
    redirect: '/user/login',
    children: [
      { path: 'users', name: 'Web Users', component: load('user/UserList') },
      {
        path: 'users/:orgId/customers/:customerId',
        name: 'Customer Users',
        component: load('user/CustomerUsers'),
      },
      {
        path: 'premium_credits',
        name: 'Premium Credits',
        component: load('user/PremiumCredits'),
      },
      {
        path: 'premium_credits/import',
        name: 'Import Premium Credits',
        component: load('user/PremiumCreditImport'),
      },
      {
        path: 'mobile_users/import',
        name: 'Import Mobile Users',
        component: load('user/Import'),
      },
      {
        path: 'settings/profile',
        name: 'UserProfile',
        component: load('user/Profile'),
      },
      {
        path: 'list/d',
        name: 'Drills for Viewer',
        component: load('drill/ListForViewer'),
      },
      {
        path: 'list/d/view/:uid',
        name: 'View Drill',
        component: load('drill/View'),
      },
      {
        path: 'list/p',
        name: 'Plans for Viewer',
        component: load('plans/ListForViewer'),
      },
      { path: 'drills', name: 'Drills', component: load('drill/Index') },
      {
        path: 'drills/view/:uid',
        name: 'drill_view',
        component: load('drill/View'),
      },
      {
        path: 'drills/import',
        name: 'Import Drill',
        component: load('drill/Import'),
      },
      {
        path: 'drills/create',
        name: 'Create Drill',
        component: load('drill/CreateUpdate'),
      },
      {
        path: 'drills/edit/:uid',
        name: 'Edit Drill',
        component: load('drill/CreateUpdate'),
      },
      {
        path: 'drills/approval',
        name: 'Approval',
        component: load('drill/Approval'),
      },
      { path: 'plans', name: 'Plans', component: load('plans/Index') },
      {
        path: 'plans/edit/:uid',
        name: 'PlansEditForm',
        component: load('plans/CreateUpdate'),
      },
      {
        path: 'plans/create',
        name: 'PlansCreateForm',
        component: load('plans/CreateUpdate'),
      },
      {
        path: 'plans/view/:uid',
        name: 'plan_view',
        component: load('plans/View'),
      },
      { path: 'skills', name: 'Skills', component: load('skills/Index') },
      {
        path: 'skills/edit/:uid',
        name: 'skill',
        component: load('skills/CreateUpdate'),
      },
      {
        path: 'skills/create',
        name: 'skill',
        component: load('skills/CreateUpdate'),
      },
      {
        path: 'skills/view/:uid',
        name: 'skill_view',
        component: load('skills/View'),
      },
      {
        path: 'tactics/create',
        name: 'tactic',
        component: load('skills/CreateUpdate'),
      },
      {
        path: 'tactics/edit/:uid',
        name: 'tactic',
        component: load('skills/CreateUpdate'),
      },
      {
        path: 'plays',
        name: 'Plays',
        component: load('play/Index'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'plays/offence/create',
        name: 'Offence Create',
        component: load('play/offence/CreateUpdate'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'plays/offence/:pid/edit',
        name: 'Edit Offence',
        component: load('play/offence/CreateUpdate'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'plays/defence/create',
        name: 'Defence Create',
        component: load('play/defence/CreateUpdate'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'plays/defence/:pid/edit',
        name: 'Edit Defence',
        component: load('play/defence/CreateUpdate'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'plays/formation/create',
        name: 'Formation Create',
        component: load('play/formation/CreateUpdate'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'plays/formation/:pid/edit',
        name: 'Edit Formation',
        component: load('play/formation/CreateUpdate'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'plays/special/create',
        name: 'Special Team Create',
        component: load('play/specialTeams/CreateUpdate'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'plays/special/:pid/edit',
        name: 'Edit Special Team',
        component: load('play/specialTeams/CreateUpdate'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'plays/view/:pid',
        name: 'Play View',
        component: load('play/View'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'playtypes',
        component: load('playtype/Index'),
        meta: { requiresOrg: ['ae test account', 'athlete era', 'football'] },
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: load('dashboard/Index'),
      },
      {
        path: 'settings',
        name: 'Settings',
        component: load('partner/settings'),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    redirect: '/user/login',
  },
];

const router = new Router({
  linkActiveClass: 'active',
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await AuthService.getCurrentUser();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresOrg = to.meta.requiresOrg;
  if (requiresAuth) {
    if (currentUser) {
      if (requiresOrg) {
        const org = requiresOrg.some((value) =>
          store.state.user.orgName.toLowerCase().includes(value.toLowerCase()),
        );
        if (!org) next('/drills');
      }

      next();
    } else {
      next('/user/login');
    }
  } else {
    next();
  }
});

export default router;
