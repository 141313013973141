import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/remote-config';
import firebaseSecrets from './firebaseSecrets';
import usaSecrets from './usaSecrets';

const mainApp = firebase.initializeApp(firebaseSecrets);
const usaApp = firebase.initializeApp(usaSecrets, 'usaApp');

const db = mainApp.firestore();
const auth = mainApp.auth();
const storage = mainApp.storage();
const currentUser = auth.currentUser;

const usersCollection = db.collection('users');
const organizationsCollection = db.collection('organizations');
const plansCollection = db.collection('practice_plans');
const drillsCollection = db.collection('drills');
const templatesCollection = db.collection('templates');
const invitationsCollection = db.collection('invitations');
const skillsCollection = db.collection('skills');
const mobileUsersCollection = db.collection('mobile_users');
const mobileUsersPremiumTokenCollection = db.collection('premium_token');
const playsCollection = db.collection('plays');
const playTypesCollection = db.collection('play_types');
const fb = mainApp;
const remoteConfig = mainApp.remoteConfig();
remoteConfig.settings = {
  minimumFetchIntervalMillis: 0,
};

remoteConfig.defaultConfig = {
  old_features: [
    'uuwpknpjU3LSS2GO1ke7',
    'QazmBw4JPILngfoNKd45',
    'DLEqAsKRPiTEa5VvtyJS',
    'HLjDsNMpyojXZ95FjkVY',
  ],
};

remoteConfig
  .ensureInitialized()
  .then(() => {
    const remoteConf = remoteConfig.getValue('deprecated_features');
    const remoteVal = remoteConf._value || '';
    const remoteConfOld = remoteConfig.getValue('old_features');
    const remoteValOld = remoteConfOld._value || '';
  })
  .catch((err) => {
    console.error('Firebase Remote Config failed to initialize', err);
  });

export {
  fb,
  db,
  auth,
  storage,
  currentUser,
  usersCollection,
  organizationsCollection,
  plansCollection,
  drillsCollection,
  templatesCollection,
  invitationsCollection,
  skillsCollection,
  mobileUsersCollection,
  mobileUsersPremiumTokenCollection,
  playsCollection,
  playTypesCollection,
  remoteConfig,
  usaApp,
};
export default firebase;
