import firebase from '@/utils/firebase';

const Auth = firebase.auth();
const db = firebase.firestore();

export default {
  async signInWithEmailAndPassword(email, password) {
    let user = null;

    await Auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        user = result.user;
      })
      .catch((err) => {
        throw err;
      });

    return user;
  },
  signOut() {
    return Auth.signOut();
  },
  async getCurrentUser() {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      return user;
    }

    return await Auth.currentUser;
  },
  async fetchOrCreateUser(fireUser) {
    const userRef = db.collection('users').doc(fireUser.uid);
    let user = null;

    await userRef
      .get()
      .then((readDoc) => {
        if (readDoc.exists) {
          user = readDoc.data();
        }
      })
      .catch((err) => {
        throw err;
      });

    if (!user) {
      user = {
        displayName: fireUser.displayName,
        email: fireUser.email,
        phoneNumber: fireUser.phoneNumber,
        isAdmin: false,
        contacts: [],
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        customMessage:
          'URGENT! This is an emergency, my last location is <LOCATION>',
      };

      await userRef.set(user);
    }

    return user;
  },
  async getIdToken() {
    return await Auth.currentUser.getIdToken(true);
  },
};
