import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store/store';
import '@/plugins/base';
import '@/plugins/vue-validate';
import '@/plugins/apexcharts';
import vuetify from '@/plugins/vuetify';
import i18n from '@/i18n';
import moment from 'moment';
import JsonCSV from 'vue-json-csv';
import '@/assets/custom.css';
import './registerServiceWorker';
import constants from './constants.js';
import ContentFilter from '@/components/ContentFilter';

Vue.config.productionTip = false;

Vue.component('DownloadCsv', JsonCSV);
Vue.component('ContentFilter', ContentFilter);

Vue.filter('formatDateNow', function (value) {
  if (!value) return '';
  let data = value.toDate();
  return moment(data).fromNow();
});

Vue.filter('formatDate', function (value, formatString) {
  formatString = formatString || 'YYYY-MM-DD';
  if (
    value.seconds === undefined &&
    value.seconds === '' &&
    value.seconds === null
  )
    return '';
  let myDate = new Date(value.seconds * 1000);
  return moment(myDate).format(formatString);
});

Vue.filter('upText', function (text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
});

if (window.localStorage.getItem('role')) {
  window.$roleCode = Vue.prototype.$roleCode =
    window.localStorage.getItem('role');
  window.$isViewer =
    window.localStorage.getItem('role') == 'VIEW' ? true : false;
} else {
  window.$isViewer = false;
}

Vue.prototype.$const = constants;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
