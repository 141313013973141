export const namespaced = true;

export const state = {
  moduleName: null,
  tags: {},
  containsFilter: false,
};

export const mutations = {
  setModule(state, name) {
    state.moduleName = name;
  },
  setFilter(state, containsFilter) {
    state.containsFilter = containsFilter;
  },
  setFilteredTags(state, filteredTags) {
    state.tags = filteredTags;
  },
  clearFilters(state) {
    state.tags = {};
    state.moduleName = null;
    state.containsFilter = false;
  },
};

export const actions = {
  setModuleName({ commit }, moduleName) {
    commit('setModule', moduleName);
  },
  setFilter({ commit }, containsFilter) {
    commit('setFilter', containsFilter);
  },
  setFilteredTags({ commit }, filteredTag) {
    commit('setFilteredTags', filteredTag);
  },
  clearFilters({ commit }) {
    commit('clearFilters');
  },
};
