import AuthService from '@/services/AuthService';
import { publishNotification } from '@/utils/storeHelpers.js';
export const namespaced = true;

const isTest = localStorage.getItem('isTest');

export const state = {
  user: JSON.parse(window.localStorage.getItem('user')) || {},
  token: window.localStorage.getItem('token') || '',
  claims: window.localStorage.getItem('claims') || {},
  role_code: window.localStorage.getItem('role') || {},
  orgId: window.localStorage.getItem('orgId') || '',
  orgName: window.localStorage.getItem('orgName') || '',
  customerId: window.localStorage.getItem('customerId') || '',
  customerName: window.localStorage.getItem('customerName') || '',
  customerEndDate: window.localStorage.getItem('customerEndDate') || '',
  customerPokenCredit: window.localStorage.getItem('customerPokenCredit') || '',
  projectId: window.localStorage.getItem('projectId') || '',
  isTest: isTest === 'true' || false,
  isLogged: false,
  isAdmin: false,
  status: [],
};
export const mutations = {
  SET_LOG_IN(state, { user, token, claims }) {
    state.isLogged = true;
    state.user = user;
    state.token = token;
    state.claims = claims;
    state.role_code = claims.role_code;
    // set in local storageclaims
    window.localStorage.setItem('user', JSON.stringify(user));
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('claims', JSON.stringify(claims));
    window.localStorage.setItem('role', claims.role_code);

    if (claims.role_code) {
      state.status = [
        { label: 'In Progress', color: 'orange' },
        { label: 'Needs Review', color: 'red' },
        { label: 'Completed', color: 'green' },
      ];

      window.$roleCode = claims.role_code;
      window.$isViewer = claims.role_code == 'VIEW' ? true : false;
    } else {
      window.$isViewer = false;
    }
  },
  SET_ADMIN_USER(state, isAdmin) {
    state.isAdmin = isAdmin;
  },
  SET_ORG(state, { orgId, orgName, projectId, isTest }) {
    state.orgId = orgId;
    state.orgName = orgName;
    state.isTest = isTest;

    window.localStorage.setItem('orgId', orgId);
    window.localStorage.setItem('orgName', orgName);
    window.localStorage.setItem('isTest', isTest);

    if (projectId) {
      state.projectId = projectId;
      window.localStorage.setItem('projectId', projectId);
    }
  },
  SET_PROJECT_ID(state, projectId) {
    if (projectId) {
      state.projectId = projectId;
      window.localStorage.setItem('projectId', projectId);
    }
  },
  SET_CUSTOMER(state, { id, name, date, credit }) {
    state.customerId = id;
    state.customerName = name;
    state.customerEndDate = date;
    state.customerPokenCredit = credit;

    window.localStorage.setItem('customerId', id || '');
    window.localStorage.setItem('customerName', name || '');
    window.localStorage.setItem('customerEndDate', date || '');
    window.localStorage.setItem('customerPokenCredit', credit || '');
  },
  LOGOUT(state) {
    state.isLogged = false;
    state.isAdmin = false;
    state.user = {};
    state.claims = {};
    state.token = '';
    state.orgId = '';
    state.orgName = '';
    state.customerId = '';
    state.customerName = '';
    state.customerEndDate = '';
    state.customerPokenCredit = '';
    state.role_code = '';
    state.status = [];
    state.projectId = '';
    state.isTest = false;
    // remove from local storage
    window.$isViewer = false;
    window.$drillStatus = [];
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('claims');
    window.localStorage.removeItem('role');
    window.localStorage.removeItem('orgId');
    window.localStorage.removeItem('orgName');
    window.localStorage.removeItem('customerId');
    window.localStorage.removeItem('customerName');
    window.localStorage.removeItem('customerEndDate');
    window.localStorage.removeItem('customerPokenCredit');
    window.localStorage.removeItem('projectId');
    window.localStorage.removeItem('isTest');
  },
};
export const actions = {
  signInWithUserAndToken(
    { commit, dispatch },
    { user, token, claims, customerEndDate, tokenCredits },
  ) {
    commit('SET_LOG_IN', { user, token, claims });
    if (claims.role_code == 'AAD') {
      commit('SET_ADMIN_USER', true);
    } else {
      commit('SET_ORG', { orgId: claims.org_id, orgName: claims.org_name });
      commit('SET_CUSTOMER', {
        id: claims.sub_id,
        name: claims.sub_name,
        date: customerEndDate,
        credit: tokenCredits,
      });
      commit('SET_ADMIN_USER', false);
    }
  },
  changeOrg({ commit, dispatch }, { orgId, orgName, projectId, isTest }) {
    commit('SET_ORG', { orgId, orgName, projectId, isTest });
  },
  changeCustomer({ commit, dispatch }, { id, name, date, credit }) {
    commit('SET_CUSTOMER', { id, name, date, credit });
  },
  async signInWithEmailAndPassword({ commit, dispatch }, form) {
    try {
      const result = await AuthService.signInWithEmailAndPassword(
        form.email,
        form.password,
      );
      if (result) {
        let successMessage = 'User successfuly signed in!';
        if (form.direct && form.direct == 'invite') {
          successMessage = 'Your new user account has been registered';
        }
        publishNotification('success', successMessage, dispatch);
      }
      return result;
    } catch (err) {
      commit('LOGOUT');
      return err;
    }
  },
  singOut({ commit, dispatch }) {
    AuthService.signOut()
      .then(() => {
        commit('LOGOUT');
      })
      .catch((err) => {
        publishNotification(
          'error',
          'There was an error when sign out. ' + err.message,
          dispatch,
        );
      });
  },
  changeProject({ commit, dispatch }, projectId) {
    commit('SET_PROJECT_ID', projectId);
  },
};
