<template>
  <div class="text-center">
    <v-menu open-on-hover offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :color="color" rounded dark v-bind="attrs" v-on="on">
          <v-icon v-if="icon !== null" left>{{ icon }}</v-icon>
          {{ title }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="loadPage(item.link)"
        >
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    title: {
      type: String,
      default: 'Dropdown',
    },
    showDropdown: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [
        {
          link: undefined,
          text: undefined,
        },
      ],
    },
  },
  methods: {
    loadPage(link) {
      this.$router.push(link);
    },
  },
};
</script>
