<template>
  <div v-if="filter">
    <v-card class="px-2">
      <v-card-title class="text-h3 font-weight-medium text-left pb-4 px-4">
        Configure {{ filter.name }} filter options
      </v-card-title>
      <v-card-subtitle class="px-5">
        Edit, delete, or create an option
      </v-card-subtitle>
      <v-card-text>
        <v-list dense>
          <template v-for="(item, index) in items">
            <v-list-item :key="index" class="px-1">
              <v-list-item-content>
                <div v-if="!item.edit">{{ item.name }}</div>
                <v-text-field
                  v-else
                  v-model="item.name"
                  placeholder="Name this option"
                  dense
                  hide-details
                />
              </v-list-item-content>
              <v-list-item-icon class="mr-2">
                <v-btn
                  v-if="item.edit"
                  small
                  icon
                  color="green"
                  class="ma-0 bg-white"
                  @click="saveOption(item)"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  small
                  icon
                  color="primary"
                  class="ma-0 bg-white"
                  @click="editOption(item)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-icon class="ml-0">
                <v-btn
                  v-if="item.edit"
                  small
                  icon
                  color="primary"
                  class="ma-0 bg-white"
                  @click="removeOption(item, index)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  small
                  icon
                  color="primary"
                  class="ma-0 bg-white"
                  @click="confirmOption(item, index)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
            <v-divider :key="index + 'divider'"></v-divider>
          </template>
          <v-list-item link class="px-1" @click="addOption">
            <v-list-item-icon class="mr-2">
              <v-icon color="primary">mdi-plus-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Add an option</v-list-item-title>
          </v-list-item>
          <v-divider />
        </v-list>
      </v-card-text>
      <v-card-actions class="pb-5 pr-3">
        <v-spacer />
        <v-btn
          color="primary"
          text
          rounded
          class="text-normal"
          @click="closeDialog"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogDelete" width="400" content-class="rounded-xl">
      <v-card>
        <v-card-title
          v-if="selectedItem"
          class="text-left mb-0 font-weight-medium"
        >
          Delete {{ selectedItem.name }}
        </v-card-title>
        <v-card-text class="pt-1 grey--text text--darken-1">
          Any {{ filter.allowedCollectionTypes.join(', ') }} tagged with this
          filter option will have the option removed. Still want to delete it?
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            rounded
            class="text-normal"
            @click="cancelDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            dark
            rounded
            depressed
            class="text-normal"
            @click="deleteOption"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from '@/utils/firebase';
import { mapState } from 'vuex';
import firebase from 'firebase/compat/app';
import { remoteConfig } from '@/utils/firebase';

export default {
  name: 'ContentFilterConfigure',
  props: {
    orgId: {
      type: String,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogConfigure: false,
      items: [],
      values: [],
      selectedItem: null,
      selectedIndex: null,
      dialogDelete: false,
      oldName: null,
    };
  },
  watch: {
    filter: {
      handler() {
        this.fetchData();
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (!this.filter.id) {
        return;
      }
      let fbCollection = db
        .collection('organizations')
        .doc(this.orgId)
        .collection('filters');

      fbCollection
        .doc(this.filter.id)
        .onSnapshot({ includeMetadataChanges: true }, (doc) => {
          if (!doc || !doc.exists) {
            return;
          }
          const values = doc.data().values || [];
          let items = [];
          values.map(function (item) {
            items.push({ name: item, edit: false });
          });
          this.items = items;
          this.values = values;
        });
    },
    closeDialog() {
      this.$emit('closeConfigure');
    },
    addOption() {
      this.items.push({
        edit: true,
        name: '',
      });
    },
    async saveOption(item) {
      try {
        let fbCollection = db
          .collection('organizations')
          .doc(this.orgId)
          .collection('filters');

        let fbDrill = db.collection('drills').where('org_id', '==', this.orgId);

        let fbSkill = db.collection('skills').where('org_id', '==', this.orgId);

        let fbPlans = db
          .collection('practice_plans')
          .where('org_id', '==', this.orgId);

        let refDrills = await fbDrill.get();

        let refSkills = await fbSkill.get();

        let refPlans = await fbPlans.get();

        const batch = db.batch();

        refDrills.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.filter.name)) {
            return;
          }
          if (newFilters[this.filter.name].length === 0) {
            return;
          }
          const idx = newFilters[this.filter.name].indexOf(this.oldName);
          if (idx < 0) {
            return;
          }
          newFilters[this.filter.name][idx] = item.name;

          const newRef = db.collection('drills').doc(post.id);
          batch.update(newRef, { filters: newFilters });
        }, this);

        refSkills.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.filter.name)) {
            return;
          }
          if (newFilters[this.filter.name].length === 0) {
            return;
          }
          const idx = newFilters[this.filter.name].indexOf(this.oldName);
          if (idx < 0) {
            return;
          }
          newFilters[this.filter.name][idx] = item.name;

          const newRef = db.collection('skills').doc(post.id);
          batch.update(newRef, { filters: newFilters });
        }, this);

        refPlans.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.filter.name)) {
            return;
          }
          if (newFilters[this.filter.name].length === 0) {
            return;
          }
          const idx = newFilters[this.filter.name].indexOf(this.oldName);
          if (idx < 0) {
            return;
          }
          newFilters[this.filter.name][idx] = item.name;

          const newRef = db.collection('practice_plans').doc(post.id);
          batch.update(newRef, { filters: newFilters });
        }, this);

        item.edit = false;
        let values = new Array();
        this.items.forEach((object) => {
          if (object.name) {
            values.push(object.name);
          }
        });

        const orgRef = fbCollection.doc(this.filter.id);
        batch.update(orgRef, {
          values: values.sort(),
        });
        await batch.commit();
        this.oldName = null;
      } catch (e) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `Failed to store option`,
        });
      }
    },
    editOption(item) {
      this.oldName = item.name;
      item.edit = true;
    },
    removeOption(item, index) {
      if (item.name) {
        item.name = this.oldName;
        item.edit = false;
      }
      if (!this.oldName && !item.name) {
        this.items.splice(index, 1);
      }
      this.oldName = null;
    },
    confirmOption(item, index) {
      this.selectedItem = item;
      this.selectedIndex = index;
      this.dialogDelete = true;
    },
    isEmpty(obj) {
      return obj && Object.keys(obj).length === 0;
    },
    async deleteOption() {
      try {
        const idx = this.values.indexOf(this.selectedItem.name);
        if (idx < 0) {
          return;
        }
        this.values.splice(idx, 1);

        let fbCollection = db
          .collection('organizations')
          .doc(this.orgId)
          .collection('filters');

        let fbDrill = db.collection('drills').where('org_id', '==', this.orgId);

        let fbSkill = db.collection('skills').where('org_id', '==', this.orgId);

        let fbPlans = db
          .collection('practice_plans')
          .where('org_id', '==', this.orgId);

        let refDrills = await fbDrill.get();

        let refSkills = await fbSkill.get();

        let refPlans = await fbPlans.get();

        const batch = db.batch();

        refDrills.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.filter.name)) {
            return;
          }
          if (newFilters[this.filter.name].length === 0) {
            return;
          }
          const idx = newFilters[this.filter.name].indexOf(
            this.selectedItem.name,
          );
          if (idx < 0) {
            return;
          }
          newFilters[this.filter.name].splice(idx, 1);

          if (newFilters[this.filter.name].length === 0) {
            delete newFilters[this.filter.name];
          }
          let updateFilters = newFilters;
          if (this.isEmpty(newFilters)) {
            updateFilters = firebase.firestore.FieldValue.delete();
          }
          const newRef = db.collection('drills').doc(post.id);
          batch.update(newRef, { filters: updateFilters });
        }, this);

        refSkills.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.filter.name)) {
            return;
          }
          if (newFilters[this.filter.name].length === 0) {
            return;
          }
          const idx = newFilters[this.filter.name].indexOf(
            this.selectedItem.name,
          );
          if (idx < 0) {
            return;
          }
          newFilters[this.filter.name].splice(idx, 1);

          if (newFilters[this.filter.name].length === 0) {
            delete newFilters[this.filter.name];
          }
          let updateFilters = newFilters;
          if (this.isEmpty(newFilters)) {
            updateFilters = firebase.firestore.FieldValue.delete();
          }
          const newRef = db.collection('skills').doc(post.id);
          batch.update(newRef, { filters: updateFilters });
        }, this);

        refPlans.forEach(async (doc) => {
          let post = {};
          post = doc.data();
          post.id = doc.id;
          if (!post.filters) {
            return;
          }
          const newFilters = post.filters;
          if (!newFilters && this.isEmpty(newFilters)) {
            return;
          }
          if (!newFilters.hasOwnProperty(this.filter.name)) {
            return;
          }
          if (newFilters[this.filter.name].length === 0) {
            return;
          }
          const idx = newFilters[this.filter.name].indexOf(
            this.selectedItem.name,
          );
          if (idx < 0) {
            return;
          }
          newFilters[this.filter.name].splice(idx, 1);

          if (newFilters[this.filter.name].length === 0) {
            delete newFilters[this.filter.name];
          }
          let updateFilters = newFilters;
          if (this.isEmpty(newFilters)) {
            updateFilters = firebase.firestore.FieldValue.delete();
          }
          const newRef = db.collection('practice_plans').doc(post.id);
          batch.update(newRef, { filters: updateFilters });
        }, this);

        const orgRef = fbCollection.doc(this.filter.id);
        batch.update(orgRef, {
          values: this.values,
        });

        await batch.commit();
        this.cancelDialog();
      } catch (e) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `Failed to remove option`,
        });
      }
    },
    cancelDialog() {
      this.selectedItem = null;
      this.selectedIndex = null;
      this.dialogDelete = false;
    },
  },
};
</script>
