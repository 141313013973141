import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '@/i18n';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';

Vue.use(Vuetify);

const vuetify = new Vuetify();

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
});

const theme = {
  primary: '#264583',
  secondary: '#264583',
  accent: '#1ce783',
  info: '#2f2f2f',
  blue: '#365ca8',
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      light: theme,
      dark: theme,
    },
  },
});
